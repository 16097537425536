import {FunctionComponent, useEffect, useState} from 'react';

import {Input} from 'antd';
import {observer} from 'mobx-react';

import {AI_MODELS} from '../../../Core/Const/AiModels';
import {AnswerMode, ERole} from '../../../Core/Enums';
import {useSearchStore} from '../../../Services/Adapters/store';
import {useStableDiffusion} from '../../../Services/Image';
import {useOpenAIChat} from '../../../Services/Openai/chat';
import {useOpenAIImage} from '../../../Services/Openai/image';
import {usePrompts} from '../../../Services/Prompts';
import {useYandexChat} from '../../../Services/Yandex';
import useStores from '../../../Stores';
import DialogueReset from '../DialogueReset';
import InputWrapper from '../InputWrapper';
import s from './styles.module.scss';

const MessageInput: FunctionComponent = () => {
    const {addMessage, setCurrentMessage, setMessageContainerHeight, isLoading, messages, aiMode, isAiAnswering} =
        useSearchStore();
    const {modelsOptions} = useStores().profileStore;
    const {sendChatMessageInStreamingMode} = useOpenAIChat();
    const {sendChatMessageInStreamingModeYandex} = useYandexChat();
    const {convertTextToImage} = useStableDiffusion();
    const {textToImage} = useOpenAIImage();
    const {currentHistoryChatId, setCurrentHistoryChatId} = useStores().historyStore;

    const [inputValue, setInputValue] = useState('');
    const {saveChat, updateChat, updateHistoryChat, saveHistory, getHistory} = usePrompts();

    const mappedModelsOptions = modelsOptions.map(({name, title}) => ({
        label: title,
        value: name,
    }));

    const getLabelByMode = (mode: string) => {
        const option = mappedModelsOptions.find((item) => item.value === mode);
        return option ? option.label : mode;
    };

    useEffect(() => {
        if (messages.length > 1 && !isAiAnswering) {
            void updateChat();
            if (currentHistoryChatId) void updateHistoryChat(currentHistoryChatId);
        }
    }, [isAiAnswering]);

    const sendMessage = async (): Promise<void> => {
        setCurrentMessage(ERole.USER, inputValue, aiMode);
        addMessage();
        setInputValue('');

        if (currentHistoryChatId) await updateHistoryChat(currentHistoryChatId);

        if (messages.length <= 1) {
            await saveChat();
            const resp = await saveHistory();
            setCurrentHistoryChatId(resp.id);

            await getHistory();
        }

        if (AI_MODELS.openai_text.includes(aiMode)) await sendChatMessageInStreamingMode();
        if (AI_MODELS.yandex.includes(aiMode)) await sendChatMessageInStreamingModeYandex();

        const handleResponse = async (resp: any, aiMode: string) => {
            const imageUrls = resp.imageUrls || [resp.imgUrl];

            imageUrls.forEach((imageUrl: any) => {
                setCurrentMessage(ERole.ASSISTANT, imageUrl, aiMode);
                addMessage();
            });

            if (messages.length > 1 && !isAiAnswering) {
                void updateChat();
                if (currentHistoryChatId) void updateHistoryChat(currentHistoryChatId);
            }
        };

        if (AI_MODELS.stable_diffusion.includes(aiMode)) {
            await handleResponse(await convertTextToImage(), aiMode);
        } else if (AI_MODELS.openai_image.includes(aiMode)) {
            await handleResponse(await textToImage(), aiMode);
        }
    };

    const handleTextAreaPressEnter = async (e: any): Promise<void> => {
        const enterKeyCode = 13;

        if (!(e.keyCode === enterKeyCode && e.shiftKey) && !isLoading && inputValue.trim()) {
            e.preventDefault();
            await sendMessage();
        }
    };

    const handleInput = (e: any): void => {
        const value = e.target.value as string;
        setInputValue(value);
    };

    const handleResize = ({height}: any): void => {
        setMessageContainerHeight(height);
    };

    const handleClick = async (): Promise<void> => {
        if (inputValue.trim()) {
            await sendMessage();
        }
    };

    return (
        <InputWrapper onClickSend={handleClick} resetControl={<DialogueReset />}>
            <Input.TextArea
                className={s.textArea}
                variant="borderless"
                onResize={handleResize}
                autoSize
                size="large"
                placeholder={
                    aiMode === AnswerMode.DALLE_2 ||
                    aiMode === AnswerMode.DALLE_3 ||
                    aiMode === AnswerMode.TEXT_TO_IMAGE
                        ? `Подробно опишите изображение для ${getLabelByMode(aiMode)}`
                        : `Введите запрос ${getLabelByMode(aiMode)}`
                }
                onInput={handleInput}
                onPressEnter={handleTextAreaPressEnter}
                value={inputValue}
            />
        </InputWrapper>
    );
};

export default observer(MessageInput);
