import {FunctionComponent, useEffect, useState} from 'react';

import {EyeInvisibleOutlined, EyeOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input, Space} from 'antd';
import {AxiosError} from 'axios';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {EHTTPStatus, EPageTitle} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';

interface IAuthData {
    login: string;
    password: string;
}

const AuthBusinessForm: FunctionComponent = () => {
    const navigate = useNavigate();
    const {loginBusiness} = useAuth();
    const [form] = Form.useForm();
    const [authErrors, setAuthErrors] = useState<string[]>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    useEffect(() => {
        document.title = EPageTitle.LOGIN;
    }, []);

    const validateMessages = {
        required: 'Поле не может быть пустым',
    };

    const handleSignIn = async (authData: IAuthData) => {
        const payload = {login: authData.login, password: authData.password};
        try {
            await loginBusiness(payload);
            navigate(ROUTES.APP.PATH, {replace: true});
        } catch (e) {
            const err = e as AxiosError;

            if (err.response?.status === EHTTPStatus.NOT_FOUND) setAuthErrors(['Пользователь не найден']);

            if (err.response?.status === EHTTPStatus.UNAUTHORIZED) setAuthErrors(['Неверный пароль']);

            if (err.response?.status === EHTTPStatus.SERVER_ERROR)
                setAuthErrors(['Что-то пошло не так, попробуйте позже']);

            if (err.response?.status === EHTTPStatus.CONFLICT)
                setAuthErrors(['Ошибка авторизации, используйте альтернативный метод входа']);
        }
    };

    const onFinish = async (authData: IAuthData) => {
        await form.validateFields();
        await handleSignIn(authData);
    };

    const handleFormChange = () => {
        const values = form.getFieldsValue();
        const isValid = values.login && values.password;
        setIsButtonDisabled(!isValid);
        setAuthErrors([]);
    };

    return (
        <Form
            className={s.authForm}
            layout="vertical"
            validateMessages={validateMessages}
            onFinish={onFinish}
            onValuesChange={handleFormChange}
            form={form}
        >
            <Form.Item className={s.formItem} name="login" rules={[{required: true}]}>
                <Input className={s.antInput} prefix={<UserOutlined className={s.icon} />} placeholder="ЛОГИН" />
            </Form.Item>
            <Form.Item className={s.formItem} name="password" rules={[{required: true}]}>
                <Input.Password
                    className={s.antInput}
                    prefix={<LockOutlined className={s.icon} />}
                    placeholder="ПАРОЛЬ"
                    iconRender={(visible) =>
                        visible ? (
                            <EyeOutlined style={{color: '#ffffff'}} />
                        ) : (
                            <EyeInvisibleOutlined style={{color: '#ffffff'}} />
                        )
                    }
                />
            </Form.Item>

            <div className={s.errorList}>
                <Form.ErrorList errors={authErrors} />
            </div>

            <Space className={s.space} direction="horizontal" align="center">
                <Button
                    type="link"
                    className={s.textButton}
                    onClick={() => navigate(ROUTES.UNAUTHORIZED.REGISTRATION.PATH)}
                >
                    Регистрация
                </Button>
                <Button
                    type="link"
                    className={s.textButton}
                    onClick={() => navigate(ROUTES.UNAUTHORIZED.PASSWORD_RESTORE.PATH)}
                >
                    Забыли пароль?
                </Button>
            </Space>

            <Form.Item className={s.formItem}>
                <Button className={s.enterButton} type="primary" htmlType="submit" disabled={isButtonDisabled}>
                    ВОЙТИ
                </Button>
            </Form.Item>
        </Form>
    );
};

export default observer(AuthBusinessForm);
