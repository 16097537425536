import {action, makeObservable, observable} from 'mobx';
import uuid from 'react-uuid';

export default class SearchStore {
    private readonly publicationTime = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
    ).toISOString();

    @observable
    sessionId: string | null = null;

    @observable
    messages: any[] = [];

    constructor() {
        makeObservable(this);
    }

    @observable
    isLoading = false;

    @observable
    isChatHistoryLoading = false;

    @observable
    isSidebarOpen = false;

    @observable
    modifier: string | null = null;

    @observable
    aiMode: string = '';

    @observable
    isAiAnswering = false;

    @action
    setIsAiAnswering = (value: boolean): void => {
        this.isAiAnswering = value;
    };

    @observable
    message = {
        rank: this.messages.length + 1,
        role: '',
        content: '',
        publication_time: this.publicationTime,
        like: false,
        dislike: false,
        mode: this.aiMode,
    };

    @observable
    chat = {
        id: uuid(),
        promptId: null,
        messages: this.messages,
    };

    @observable
    currentChatId = uuid();

    @observable
    messageContainerHeight = 0;

    @action
    addMessage = (): void => {
        this.message.rank = this.messages.length + 1;
        this.messages.push(this.message);
    };

    @action
    setMessages = (messages: any[]): void => {
        this.messages = messages;
    };

    @action
    setCurrentChatId = (value: string): void => {
        this.currentChatId = value;
    };

    @action
    setCurrentMessage = (role: string, content: string, mode: string = this.aiMode): void => {
        this.message = {
            rank: this.messages.length + 1,
            publication_time: this.publicationTime,
            like: false,
            dislike: false,
            role,
            content,
            mode,
        };
    };

    @action
    appendTextToCurrentMessage = (text: string): void => {
        this.message.content += text;
    };

    @action
    clearMessages = (): void => {
        this.messages = [];
    };

    @action
    setMessageLike = (likedMessage: any): void => {
        this.messages.forEach((message) => {
            if (message === likedMessage) {
                message.like = true;
                message.dislike = false;
            }
        });
    };

    @action
    setMessageDisLike = (dislikedMessage: any): void => {
        this.messages.forEach((message) => {
            if (message === dislikedMessage) {
                message.like = false;
                message.dislike = true;
            }
        });
    };

    @action
    setMessageContainerHeight = (value: number): void => {
        this.messageContainerHeight = value;
    };

    @action
    resetMessages = (): void => {
        this.messages = [];
        this.currentChatId = uuid();
    };

    @action
    setIsLoading = (value: boolean): void => {
        this.isLoading = value;
    };

    @action
    setIsChatHistoryLoading = (value: boolean): void => {
        this.isChatHistoryLoading = value;
    };

    @action
    setAiMode = (value: string): void => {
        this.aiMode = value;
    };

    @action
    setSessionId = (value: string): void => {
        this.sessionId = value;
    };

    @action
    setIsSidebarOpen = (value: boolean): void => {
        this.isSidebarOpen = value;
    };

    @action
    setModifier = (value: string | null): void => {
        this.modifier = value;
    };
}
