import {FunctionComponent, useEffect, useState} from 'react';

import {Layout, Typography, Flex, Button, Divider} from 'antd';
import {ReactComponent as GoogleLogo} from 'Assets/svg/google-logo.svg';
import {ReactComponent as YandexLogo} from 'Assets/svg/yandexgpt.svg';
import {AUTH_LOGIN_YANDEX_ENDPOINT, AUTH_LOGIN_GOOGLE_ENDPOINT} from 'Core/Const/Endpoints';
import {BACKEND_SERVICE_URL} from 'Core/Const/Env';
import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';

import s from './styles.module.scss';
import {EPageTitle} from '../../../Core/Enums';
import AuthLoginBusinessForm from '../AuthLoginBusinessForm';
import AuthLoginIndividualForm from '../AuthLoginIndividualForm';

enum ELoginTabs {
    individual,
    business,
}

const AuthLoginPage: FunctionComponent = () => {
    const [activeTab, setActiveTab] = useState<ELoginTabs>(ELoginTabs.individual);

    const renderTabContent = () => {
        switch (activeTab) {
            case ELoginTabs.individual:
                return <AuthLoginIndividualForm />;
            case ELoginTabs.business:
                return <AuthLoginBusinessForm />;
        }
    };

    const handleYandexRedirect = () => {
        const yandexQueryString = `?redirect_uri=${window.location.origin}${ROUTES.UNAUTHORIZED.LOGIN_YANDEX.PATH}`;
        const yandexRedirectUrl = `${BACKEND_SERVICE_URL}${AUTH_LOGIN_YANDEX_ENDPOINT}${yandexQueryString}`;
        window.location.replace(yandexRedirectUrl);
    };

    const handleGoogleRedirect = () => {
        const googleQueryString = `?redirect_uri=${window.location.origin}${ROUTES.UNAUTHORIZED.LOGIN_GOOGLE.PATH}`;
        const googleRedirectUrl = `${BACKEND_SERVICE_URL}${AUTH_LOGIN_GOOGLE_ENDPOINT}${googleQueryString}`;
        window.location.replace(googleRedirectUrl);
    };

    useEffect(() => {
        document.title = EPageTitle.LOGIN;
    }, []);

    return (
        <Layout className={s.authPage}>
            <Typography.Title className={s.title}>AI Rabbit</Typography.Title>
            <Flex gap="middle">
                <Button
                    type="link"
                    className={activeTab === ELoginTabs.individual ? s.tabButtonActive : s.tabButton}
                    onClick={() => setActiveTab(ELoginTabs.individual)}
                >
                    Физическое лицо
                </Button>
                <Button
                    type="link"
                    className={activeTab === ELoginTabs.business ? s.tabButtonActive : s.tabButton}
                    onClick={() => setActiveTab(ELoginTabs.business)}
                >
                    Юридическое лицо
                </Button>
            </Flex>
            <div className={s.tabContent}>{renderTabContent()}</div>
            <Divider className={s.divider}>ИЛИ</Divider>
            <Button className={s.serviceUrl} type="primary" icon={<YandexLogo />} onClick={handleYandexRedirect}>
                Продолжить с Яндекс
            </Button>
            <Button className={s.serviceUrl} type="primary" icon={<GoogleLogo />} onClick={handleGoogleRedirect}>
                Продолжить с Google
            </Button>
        </Layout>
    );
};

export default observer(AuthLoginPage);
