import Auth from '../../Api/Auth';
import {AI_MODELS} from '../../Core/Const/AiModels';
import useStores from '../../Stores';
import {IModelAi} from '../../Stores/Profile/types';
import {removeAuthTokens, setAuthTokens} from '../LocalStorage';

export const useAuth = () => {
    const api = new Auth();
    const {setLogin, setEmail, setAvailablePacks, setModelsOptions, setAiModelsRequests} = useStores().profileStore;
    const {setAiMode} = useStores().searchStore;

    return {
        async loginIndividual(payload: any) {
            const {data} = await api.loginIndividual(payload);
            setAuthTokens(data.access_token, data.refresh_token);
        },

        async loginBusiness(payload: any) {
            const {data} = await api.loginBusiness(payload);
            setAuthTokens(data.access_token, data.refresh_token);
        },

        async loginYandex(code: string, redirectUri: string) {
            const {data} = await api.loginYandex(code, redirectUri);
            setAuthTokens(data.access_token, data.refresh_token);
        },

        async loginGoogle(code: string, redirectUri: string) {
            const {data} = await api.loginGoogle(code, redirectUri);
            setAuthTokens(data.access_token, data.refresh_token);
        },

        async logout() {
            await api.logout();
            removeAuthTokens();
        },

        async changeLogin(newLogin: string) {
            await api.changeLogin(newLogin);
        },

        async changePassword(payload: any) {
            await api.changePassword(payload);
        },

        async registration(payload: any, successUri: string) {
            await api.registration(payload, successUri);
        },

        async restorePassword(payload: any) {
            await api.passwordRestore(payload);
        },

        async resetPassword(payload: any) {
            await api.passwordReset(payload);
        },

        async resendEmailConfirmation(payload: any) {
            await api.resendEmailConfirmation(payload);
        },

        async getUser() {
            const {data} = await api.getUser();
            setLogin(data.login);
            setEmail(data.email);
        },

        async getAvailablePacks() {
            const {data} = await api.getAvailablePacks();
            setAvailablePacks(data);
        },

        async getAiModels() {
            const {data} = await api.getAiModels();
            setModelsOptions(data);

            const index = AI_MODELS.openai_text.indexOf('gpt-4o-mini');

            const defaultMode =
                data.find((model: IModelAi) => model.name === AI_MODELS.openai_text[index])?.name ||
                data.find((model: IModelAi) => AI_MODELS.openai_text.includes(model.name))?.name ||
                data[index].name;
            setAiMode(defaultMode);
        },

        async getPackPaymentLink(packId: string) {
            const {data} = await api.getPackPaymentLink(packId);
            return data.link;
        },

        async getAiModelsRequests() {
            const {data} = await api.getAiModelsRequests();
            setAiModelsRequests(data);
        },
    };
};
