import {FunctionComponent, useState} from 'react';

import {Button, Form, Input, Flex, Typography} from 'antd';
import {AxiosError} from 'axios';
import {observer} from 'mobx-react';

import s from './styles.module.scss';
import {EHTTPStatus} from '../../../Core/Enums';
import {useAuth} from '../../../Services/Auth';

const {Title} = Typography;

const ProfileSettingsChangePassword: FunctionComponent = () => {
    const [form] = Form.useForm();
    const {changePassword} = useAuth();
    const [changePasswordErrors, setChangePasswordErrors] = useState<string[]>([]);

    const handleSubmit = async () => {
        const values = await form.validateFields();
        const payload = {old_password: values.oldPassword, new_password: values.newPassword};
        try {
            await changePassword(payload);
            form.resetFields();
            setChangePasswordErrors([]);
        } catch (e) {
            const err = e as AxiosError;

            if (err.response?.status === EHTTPStatus.CONFLICT)
                setChangePasswordErrors(['Введен неверный старый пароль']);

            if (err.response?.status === EHTTPStatus.SERVER_ERROR)
                setChangePasswordErrors(['Что-то пошло не так, попробуйте позже']);
        }
    };

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} не может быть пустым',
    };

    return (
        <Flex vertical={true} className={s.wrapper}>
            <Title className={s.title} level={4}>
                Смена пароля
            </Title>
            <Form layout="vertical" form={form} validateMessages={validateMessages}>
                <Form.Item className={s.formItem} label="Старый пароль" name="oldPassword" rules={[{required: true}]}>
                    <Input.Password className={s.antInput} />
                </Form.Item>
                <Form.Item className={s.formItem} label="Новый пароль" name="newPassword" rules={[{required: true}]}>
                    <Input.Password className={s.antInput} />
                </Form.Item>
                <Form.Item
                    className={s.formItem}
                    label="Подтверждение пароля"
                    name="confirmNewPassword"
                    dependencies={['newPassword']}
                    rules={[
                        {
                            required: true,
                        },
                        ({getFieldValue}) => ({
                            async validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) return await Promise.resolve();
                                return await Promise.reject(new Error('Пароли не совпадают'));
                            },
                        }),
                    ]}
                >
                    <Input.Password className={s.antInput} />
                </Form.Item>
                <div className={s.errorList}>
                    <Form.ErrorList errors={changePasswordErrors} />
                </div>
                <Button className={s.button} type="primary" htmlType="submit" onClick={handleSubmit}>
                    Сохранить
                </Button>
            </Form>
        </Flex>
    );
};

export default observer(ProfileSettingsChangePassword);
