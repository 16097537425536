import {FunctionComponent, RefObject, useEffect, useState} from 'react';

import {CopyOutlined, EditOutlined} from '@ant-design/icons';
import {Button, Col, Row, Image as AntdImage, Typography} from 'antd';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import MessageContent from './MessageContent';
import s from './styles.module.scss';
import {ERole, AnswerMode} from '../../../Core/Enums';
import {delay} from '../../Utils';

interface IProps {
    message: any;
    bottomRef?: RefObject<any>;
}

const Message: FunctionComponent<IProps> = ({message, bottomRef}: IProps) => {
    const containerClass = message.role === ERole.USER ? 'userContainer' : 'notUserContainer';
    const {modelsOptions} = useStores().profileStore;
    const [image, setImage] = useState(<></>);
    const {modifier} = useStores().searchStore;

    useEffect(() => {
        const messageContent = async (): Promise<void> => {
            const delayMs = 1000;

            if (
                (message.mode === AnswerMode.DALLE_2 ||
                    message.mode === AnswerMode.DALLE_3 ||
                    message.mode === AnswerMode.TEXT_TO_IMAGE) &&
                message.role === ERole.ASSISTANT
            ) {
                await delay(delayMs);
                const img = new Image();
                img.src = message.content;
                img.onload = () => {
                    setImage(<AntdImage src={message.content} />);
                    bottomRef?.current?.scrollIntoView({behavior: 'smooth'});
                };
            }
        };

        void messageContent();
    }, [message]);

    const modelTitle =
        message.role === ERole.ASSISTANT
            ? modelsOptions.find((item) => {
                  return item.name === message.mode;
              })?.title
            : 'Вы';

    const handleOnClickCopy = async (): Promise<void> => {
        await navigator.clipboard.writeText(message.content);
    };

    return (
        <Row className={classNames(s[containerClass], modifier && s[`${containerClass}--${modifier}`])}>
            <Row justify="space-between" wrap>
                <Row align="top">
                    <Col className={s.messageCol}>
                        {(message.mode === AnswerMode.DALLE_2 ||
                            message.mode === AnswerMode.DALLE_3 ||
                            message.mode === AnswerMode.TEXT_TO_IMAGE) &&
                        message.role === ERole.ASSISTANT ? (
                            image
                        ) : (
                            <Row>
                                <MessageContent content={message.content} />
                            </Row>
                        )}
                    </Col>
                </Row>
                {message.role === ERole.ASSISTANT ? (
                    <Row className={s.controlsRow} align="middle">
                        <Button
                            type="link"
                            size="large"
                            icon={<EditOutlined className={s.copyIcon} />}
                            className={s.messageActBtn}
                        />
                        <Button
                            type="link"
                            size="large"
                            icon={<CopyOutlined className={s.copyIcon} />}
                            className={s.messageActBtn}
                            onClick={handleOnClickCopy}
                        />
                        <Typography.Text className={s.modelTitle}>{modelTitle}</Typography.Text>
                    </Row>
                ) : (
                    <></>
                )}
            </Row>
        </Row>
    );
};

export default observer(Message);
