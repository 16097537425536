import {FunctionComponent, useState} from 'react';

import {FormOutlined, RightOutlined, BarsOutlined} from '@ant-design/icons';
import {Button, Divider, Flex, Typography, Popover} from 'antd';
import classNames from 'classnames';
import {EProfileTabs} from 'Core/Enums';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import useStores from 'Stores';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {useAuth} from '../../../Services/Auth';

const {Title} = Typography;

interface HeaderProfileProps {
    activeTab: EProfileTabs;
    setActiveTab: (tab: EProfileTabs) => void;
}

const HeaderProfile: FunctionComponent<HeaderProfileProps> = ({activeTab, setActiveTab}) => {
    const navigate = useNavigate();
    const {setPrompt} = useStores().promptStore;
    const {setCurrentHistoryChatId, setCurrentHistoryChatTitle} = useStores().historyStore;
    const {resetMessages, setIsLoading, setIsSidebarOpen, modifier} = useStores().searchStore;
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const {logout} = useAuth();

    const handleLogout = () => {
        void logout();
        navigate(ROUTES.UNAUTHORIZED.LOGIN.PATH);
    };

    const PopoverContent = (
        <Flex vertical gap="small">
            <Button
                type="default"
                shape="round"
                onClick={() => {
                    setActiveTab(EProfileTabs.settings);
                    setIsPopoverOpen(false);
                }}
                className={activeTab === EProfileTabs.settings ? s.buttonActive : s.button}
            >
                Настройки
            </Button>
            <Button
                type="default"
                shape="round"
                onClick={() => {
                    setActiveTab(EProfileTabs.balances);
                    setIsPopoverOpen(false);
                }}
                className={activeTab === EProfileTabs.balances ? s.buttonActive : s.button}
            >
                Остаток запросов
            </Button>
            <Button
                type="default"
                shape="round"
                onClick={handleLogout}
                className={activeTab === EProfileTabs.balances ? s.buttonActive : s.button}
            >
                Выйти
            </Button>
        </Flex>
    );

    return (
        <>
            {modifier !== 'Mobile' ? (
                <Flex align="center" gap="large" className={classNames(s.flex, modifier && s[`flex--${modifier}`])}>
                    <Title className={s.title} level={1}>
                        Изменение профиля
                    </Title>
                    {modifier !== 'LargeMobile' ? (
                        <Flex className={s.tabs} align="center" gap="large">
                            <Button
                                type="default"
                                shape="round"
                                onClick={() => setActiveTab(EProfileTabs.settings)}
                                className={activeTab === EProfileTabs.settings ? s.buttonActive : s.button}
                            >
                                Настройки
                            </Button>
                            <Button
                                type="default"
                                shape="round"
                                onClick={() => setActiveTab(EProfileTabs.balances)}
                                className={activeTab === EProfileTabs.balances ? s.buttonActive : s.button}
                            >
                                Остаток запросов
                            </Button>
                            <Button type="default" shape="round" onClick={handleLogout} className={s.button}>
                                Выйти
                            </Button>
                        </Flex>
                    ) : (
                        <>
                            <Popover
                                content={PopoverContent}
                                placement="bottomRight"
                                trigger="click"
                                arrow={false}
                                className={s.popover}
                                open={isPopoverOpen}
                            >
                                <Button
                                    size="large"
                                    type="default"
                                    icon={<BarsOutlined />}
                                    shape="circle"
                                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                />
                            </Popover>
                        </>
                    )}
                </Flex>
            ) : (
                <Flex align="center" gap="large" justify="space-between" className={s.mobileFlex}>
                    <Flex className={s.siderContainer} gap="middle">
                        <Button
                            size="large"
                            type="default"
                            icon={<RightOutlined />}
                            shape="circle"
                            onClick={() => {
                                setIsSidebarOpen(true);
                            }}
                        />
                        <Button
                            size="large"
                            type="default"
                            icon={<FormOutlined />}
                            shape="circle"
                            className={s.newChatBtn}
                            onClick={() => {
                                setIsLoading(false);
                                resetMessages();
                                setPrompt(null);
                                setCurrentHistoryChatId(null);
                                setCurrentHistoryChatTitle(null);
                                navigate(ROUTES.APP.PATH);
                            }}
                        />
                    </Flex>
                    <Popover
                        content={PopoverContent}
                        placement="bottomRight"
                        trigger="click"
                        arrow={false}
                        className={s.popover}
                        open={isPopoverOpen}
                    >
                        <Button
                            size="large"
                            type="default"
                            icon={<BarsOutlined />}
                            shape="circle"
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        />
                    </Popover>
                </Flex>
            )}
            <Divider className={s.divider} />
        </>
    );
};

export default observer(HeaderProfile);
