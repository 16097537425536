const V1 = 'api/v1';

export const AUTH_PASSWORD_CHANGE_ENDPOINT = `/${V1}/auth/credentials/password/change/`;
export const AUTH_LOGIN_CHANGE_ENDPOINT = `/${V1}/auth/credentials/login/change/`;
export const AUTH_LOGIN_INDIVIDUAL_ENDPOINT = `/${V1}/auth/login/individuals/`;
export const AUTH_LOGIN_BUSINESS_ENDPOINT = `/${V1}/auth/login/business/`;
export const AUTH_LOGIN_YANDEX_ENDPOINT = `/${V1}/oauth/yandex/login/`;
export const AUTH_LOGIN_YANDEX_CALLBACK_ENDPOINT = `/${V1}/oauth/yandex/callback/`;
export const getYandexLoginEndpoint = (code: string, redirectUri: string): string =>
    `/${V1}/oauth/yandex/callback?${code}&redirect_uri=${redirectUri}`;
export const AUTH_LOGIN_GOOGLE_ENDPOINT = `/${V1}/oauth/google/login/`;
export const AUTH_LOGIN_GOOGLE_CALLBACK_ENDPOINT = `/${V1}/oauth/google/callback/`;
export const getGoogleLoginEndpoint = (code: string, redirectUri: string): string =>
    `/${V1}/oauth/google/callback?${code}&redirect_uri=${redirectUri}`;
export const AUTH_LOGOUT_ENDPOINT = `/${V1}/auth/logout/`;
export const AUTH_TOKEN_REFRESH_ENDPOINT = `/${V1}/auth/token/refresh/`;
export const AUTH_REGISTRATION_ENDPOINT = `/${V1}/auth/registration/`;
export const getRegistrationEndpoint = (successUri: string): string => `/${V1}/auth/registration?${successUri}`;
export const AUTH_VERIFY_ACCESS_TOKEN_ENDPOINT = `/${V1}/auth/token/verify/`;
export const AUTH_PASSWORD_RESTORE_ENDPOINT = `/${V1}/auth/password/restore/`;
export const AUTH_PASSWORD_RESET_ENDPOINT = `/${V1}/auth/password/reset/`;
export const AUTH_RESEND_EMAIL_CONFIRMATION_ENDPOINT = `/${V1}/auth/registration/confirmation/resend/`;

export const CHAT_ENDPOINT = `/${V1}/chat/`;
export const getChatEndpoint = (id: string): string => `/${V1}/chat/${id}/`;

export const PACK_ENDPOINT = `/${V1}/pack/`;
export const getPackPaymentEndpoint = (id: string): string => `/${V1}/pack/${id}/payment/`;

export const PROMT_ENDPOINT = `/${V1}/prompt/`;
export const getPromtEndpoint = (id: number): string => `/${V1}/prompt/${id}/`;

export const SAVED_CHAT_ENDPOINT = `/${V1}/saved_chat/`;
export const getSavedChatEndpoint = (id: number): string => `/${V1}/saved_chat/${id}/`;

export const USER_ENDPOINT = `/${V1}/user/`;

export const USER_AI_MODEL_ENDPOINT = `/${V1}/user/ai_model/`;
export const USER_AI_MODEL_REQUESTS_ENDPOINT = `/${V1}/user/ai_model/requests/`;

export const OPENAI_CHAT_ENDPOINT = `/${V1}/openai/${V1}/chat`;
export const OPENAI_TEXT_TO_IMAGE_ENDPOINT = `/${V1}/openai/${V1}/text2img`;

export const BARD_CHAT_ENDPOINT = `/${V1}/bard/${V1}/chat`;

export const YANDEX_CHAT_ENDPOINT = `/${V1}/yandex/${V1}/completion`;

export const STABLE_DIFFUSION_TEXT_TO_IMAGE_ENDPOINT = `/${V1}/stable_diffusion/${V1}/text2img`;
