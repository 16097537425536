import React, {FunctionComponent, useEffect, useRef, useState} from 'react';

import {LoadingOutlined} from '@ant-design/icons';
import {Empty, Row, Space, Spin} from 'antd';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import useStores from 'Stores';

import {AnswerMode} from '../../../Core/Enums';
import {useSearchStore} from '../../../Services/Adapters/store';
import {delay} from '../../Utils';
import Message from '../Message';
import s from './styles.module.scss';

const MessageContainer: FunctionComponent = () => {
    const bottomRef = useRef<HTMLDivElement>(null);
    const {message, messages, isLoading, aiMode, isChatHistoryLoading} = useSearchStore();
    const [preLoaderText, setPreLoaderText] = useState('');
    const {modifier} = useStores().searchStore;

    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [message.content, messages, preLoaderText]);

    useEffect(() => {
        const getPreloader = async (): Promise<void> => {
            const delayMs = 4000;

            if (aiMode === AnswerMode.DALLE_2 || aiMode === AnswerMode.DALLE_3 || aiMode === AnswerMode.TEXT_TO_IMAGE) {
                setPreLoaderText('создаю изображение...');
                await delay(delayMs);
                setPreLoaderText('улучшаю качество изображения...');
            } else {
                setPreLoaderText('формулирую лучший ответ...');
            }
        };

        void getPreloader();
    }, [message]);

    return (
        <Space.Compact block className={s.spaceCompact}>
            <div className={s.spaceContainer}>
                {isChatHistoryLoading ? (
                    <div className={s.emptyContainer}>
                        <Spin size="large" />
                    </div>
                ) : messages.length > 0 ? (
                    messages.map((message, index) =>
                        message.role === 'end-of-context' ? (
                            <div key={index} className={s.endOfContext}>
                                Конец диалога
                            </div>
                        ) : (
                            <Message key={index} message={message} bottomRef={bottomRef} />
                        )
                    )
                ) : (
                    <div className={classNames(s.emptyContainer, modifier && s[`emptyContainer--${modifier}`])}>
                        <Empty description={false} className={s.emptyImg} />
                    </div>
                )}
                {isLoading ? (
                    <Row className={classNames(s.loadingRow, modifier && s[`loadingRow--${modifier}`])}>
                        <Row justify="space-between" wrap={true}>
                            <Row align="middle">
                                <Row className={s.withAvatarRow}>
                                    <LoadingOutlined className={s.loadingOutlined} />
                                    <ReactMarkdown>{preLoaderText}</ReactMarkdown>
                                </Row>
                            </Row>
                        </Row>
                    </Row>
                ) : (
                    <></>
                )}
                <div ref={bottomRef} />
            </div>
        </Space.Compact>
    );
};

export default observer(MessageContainer);
