import {FunctionComponent, useEffect} from 'react';

import {Row} from 'antd';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';

import {AI_MODELS} from '../../../Core/Const/AiModels';
import ROUTES from '../../../Core/Const/Routes';
import {ERole} from '../../../Core/Enums';
import {usePromptStore, useSearchStore} from '../../../Services/Adapters/store';
import {useStableDiffusion} from '../../../Services/Image';
import {useOpenAIChat} from '../../../Services/Openai/chat';
import {useOpenAIImage} from '../../../Services/Openai/image';
import {usePrompts} from '../../../Services/Prompts';
import useStores from '../../../Stores';
import InputWrapper from '../InputWrapper';
import PromptReset from '../PromptReset';
import PromptTemplate from '../PromptTemplate';
import s from './styles.module.scss';

const PromptInput: FunctionComponent = () => {
    const navigate = useNavigate();
    const {message} = usePromptStore();
    const {addMessage, setCurrentMessage, messages, aiMode, isAiAnswering} = useSearchStore();
    const {sendChatMessageInStreamingMode} = useOpenAIChat();
    const {convertTextToImage} = useStableDiffusion();
    const {textToImage} = useOpenAIImage();
    const {saveChat, updateHistoryChat, saveHistory, getHistory} = usePrompts();
    const {currentHistoryChatId, setCurrentHistoryChatId} = useStores().historyStore;

    useEffect(() => {
        if (messages.length > 1 && !isAiAnswering) {
            if (currentHistoryChatId) void updateHistoryChat(currentHistoryChatId);
        }
    }, [isAiAnswering]);
    const handleOnClickSend = async (): Promise<void> => {
        if (message.length) {
            navigate(ROUTES.APP.PATH);
            setCurrentMessage(ERole.USER, message);
            await sendMessage();
        }
    };

    // const sendMessage = async (): Promise<void> => {
    //     addMessage();
    //     if (messages.length <= 1) await saveChat(prompt?.id);
    //     await sendChatMessageInStreamingMode();
    //     if (messages.length > 1) await updateChat();
    // };

    const sendMessage = async (): Promise<void> => {
        setCurrentMessage(ERole.USER, message, aiMode);
        addMessage();

        if (currentHistoryChatId) await updateHistoryChat(currentHistoryChatId);

        if (messages.length <= 1) {
            await saveChat();
            const resp = await saveHistory();
            setCurrentHistoryChatId(resp.id);

            await getHistory();
        }

        if (AI_MODELS.openai_text.includes(aiMode)) await sendChatMessageInStreamingMode();

        if (AI_MODELS.stable_diffusion.includes(aiMode)) {
            const resp = await convertTextToImage();

            resp.imageUrls.forEach((imageUrl: any) => {
                setCurrentMessage(ERole.ASSISTANT, imageUrl);
                addMessage();
            });
        }

        if (AI_MODELS.openai_image.includes(aiMode)) {
            const resp = await textToImage();
            setCurrentMessage(ERole.ASSISTANT, resp.imgUrl);
            addMessage();
        }
    };

    return (
        <InputWrapper onClickSend={handleOnClickSend} resetControl={<PromptReset />}>
            <Row className={s.messageRow}>
                <PromptTemplate sendMessage={sendMessage} />
            </Row>
        </InputWrapper>
    );
};
export default observer(PromptInput);
