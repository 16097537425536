import {FunctionComponent} from 'react';

import {Space, Typography, Flex} from 'antd';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import s from './styles.module.scss';

const {Text, Title} = Typography;

const ProfileSettingsInfo: FunctionComponent = () => {
    const {login, email} = useStores().profileStore;

    return (
        <Flex vertical={true} className={s.wrapper}>
            <Title className={s.title} level={4}>
                Информация
            </Title>
            <Space className={s.text}>
                <Text>Логин: </Text>
                <Text>{login}</Text>
            </Space>
            <Space className={s.text}>
                <Text>Email: </Text>
                <Text>{email}</Text>
            </Space>
        </Flex>
    );
};

export default observer(ProfileSettingsInfo);
