import {FunctionComponent} from 'react';

import {Flex} from 'antd';
import {observer} from 'mobx-react';

import ProfileSettingsChangePassword from '../ProfileSettingsChangePassword';
import ProfileSettingsInfo from '../ProfileSettingsInfo';
import s from './styles.module.scss';

const ProfileSettings: FunctionComponent = () => {
    return (
        <Flex wrap justify="center" className={s.wrapper}>
            <ProfileSettingsInfo />
            <ProfileSettingsChangePassword />
        </Flex>
    );
};

export default observer(ProfileSettings);
